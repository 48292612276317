import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// styles
const pageStyles = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}
const headingStyles = {
  fontFamily: 'HelveticaNeueRegular',
  fontSize: '14rem'
}

const link = {
  fontFamily: 'HelveticaNeueRegular',
  fontSize: '5rem',
  textDecoration: 'underline',
  color: 'black'
}

// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>U.I.WD. - Not Found</title>
          <link rel="icon" type="image/png" href="/favicon.ico"></link>
          <meta property="og:site_name" content="U.I.WD. Creative Studio" />
          <meta property="og:title" content="U.I.WD. Creative Studio" />
          <meta property="og:url" content="https://uiwd.co" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="/share.png" />
          <meta name="twitter:title" content="U.I.WD Creative Studio" />
          <meta name="twitter:image" content="/share.png" />
          <meta name="twitter:url" content="https://uiwd.co" />
          <meta name="twitter:card" content="summary" />
          <meta name="description" content="U.I.WD. is a creative direction studio based in São Paulo. Specialising in Art Direction, Digital, Product, Print and Space." />
        </Helmet>
      <Layout>
        <main style={pageStyles}>
          <h1 style={headingStyles}>Not Found</h1>
        </main>
      </Layout>
    </>
  )
}

export default NotFoundPage
